<template>
	<div class="container">
		<Header   :flag="flag"/>	
		<div class="banner">
			 <div class="banner-content">
				 <div class="banner-title">
					 <span class="banner-sub-title">倾心打造短信群发，实现避开封号风险</span>
					 <br/>
					 <span class="banner-sub-title1">短信通知、短息营销</span>
					 <br/>
					 <span class="banner-sub-title1">短信群发,单价低至0.03/条</span>
					 <br/>
					 <div class="banner-qrcode">
						 <div class="qr-div1">
							 <img src="../assets/message_android.png" width="150px" height="150px" />
							 <br />
							 <span class="qr-sub-text" @click="goAndroid()">安卓版</span>
						 </div>
						 <div class="qr-div">
							 <img src="../assets/message_ios.png" width="150px" height="150px" />
							 <br />
							 <span class="qr-sub-text" @click="goIos()">苹果版</span>
						 </div>
						<div class="qr-text-div">
							 <el-link href="https://ms.lanmeihulian.com" :underline="false" class="qr-text-link" target="_blank">网页版，去登录></el-link>
						</div>
					 </div>
					 
				 </div>
				 <div class="r_img">
					 <img src="../assets/img/msg_phone_bg.png" />
				 </div>
			 </div>
		</div>
		<div class="main">
			<div class="msg">
				<div class="msg-title">
					短信群发营销
					<div class="msg-border"></div>
				</div>
				<div class="msg-sub-title">
					专业高端短信群发软件，三网合一网络佳通道，实现短信“100%实发、失败退还”模式；总体具有省心、省力、省钱、省时等优势
				</div>
				<div class="msg-content">
					<div class="msg-content-l">
						<div class="msg-box" v-for="item in msgList">
							<div class="msg-box-text">
								<span class="box-text-title">{{item.title}}：</span>
								<br/>
								<span class="box-text">{{item.intro}}</span>
							</div>
							<div class="msg-border"><div class="msg-border-sub"></div></div>
						</div>
					</div>
					<div class="msg-content-m">
						<img src="../assets/img/ic_phone.png" />
					</div>
					<div class="msg-content-r">
						<div class="msg-box" v-for="item in msgList1">
							<div class="msg-box-text">
								<span class="box-text-title">{{item.title}}：</span>
								<br/>
								<span class="box-text">{{item.intro}}</span>
							</div>
							<div class="msg-border"><div class="msg-border-sub"></div></div>
						</div>
					</div>
				</div>
			</div>
			<div class="industry">
				<div class="industry-title">
					适用行业
					<br/>
					<span class="industry-subtitle">短信群发适合于各种行业</span>
				</div>
				<div class="industry-content">
					<div class="industry-box" v-for="item in industry" >
						<div class="box-icon" v-if="item.image!=null && item.image !=''">
							<img :src="item.image"/></div>
						<div class="box-intro">
							<span class="title">{{item.title}}</span>
							<br/>
							<span class="intro">{{item.intro}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="process">
				<div class="process-title">
					短信群发营销使用流程
					<div class="msg-border"></div>
				</div>
				<div class="process-content">
					<ul class="process-ul">
						<li class="process-ul-li"><img src="../assets/img/yx_1.png" align="absmiddle" />
						<span class="sub-title">注册、完善信息</span>
						</li>
						<li class="process-ul-li-icon"><img src="../assets/img/yx_arrow.png" /></li>
						<li class="process-ul-li"><img src="../assets/img/yx_3.png" align="absmiddle" />
						<span class="sub-title">开通套餐</span>
						</li>
						<li class="process-ul-li-icon"><img src="../assets/img/yx_arrow.png" /></li>
						<li class="process-ul-li"><img src="../assets/img/yx_6.png" align="absmiddle" />
						<span class="sub-title">发短信</span>
						</li>
						<li class="process-ul-li-icon"><img src="../assets/img/yx_arrow.png" /></li>
						<li class="process-ul-li"><img src="../assets/img/yx_5.png" align="absmiddle" />
						<span class="sub-title">如有问题，咨询客服</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
		
		<Footer />
	</div>
</template>

<script>
	import Header from '@/components/Header.vue'
	import Footer from '@/components/Footer.vue'
	export default {
		metaInfo: {
			title: '短信群发营销-短信群发系统-一键群发-[蓝莓科技触达]',
			meta: [
			  {
				name: 'keywords',
				content: '短信群发营销,短信群发系统,一键群发'
			  },
			  {
				name: 'description',
				content: '蓝莓科技短信群发系统是专业高端短信群发软件，三网合一网络佳通道，到达高速、短信有效性高、通道稳定，可大幅提高线索有效率。'
			  }
			]
		},
		name: 'Message',
		components: {
			Header,
			Footer
		},	
		data(){
			return{
				flag:'solution',
				msgList:[
					{
						title: "高到达率",
						intro: "高效优质群发短信通道，99%高到达率（不包含空号或不在服务区等特殊情况）"
					},
					{
						title: "通道稳定",
						intro: "移动、联通、电信三网合一，106群发短信专属通道，不堵塞，性能稳定。"
					},
					{
						title: "审核效率高",
						intro: "信息审核高效迅速，保障客户及时性。"
					}
				],
				msgList1:[
					{
						title: "短信有效性",
						intro: "短信发送过程中，短信发送成功则计费，发送失败自动返还；在移动端，开放的客户发送状态记录，透明的消费，账单清晰明了。"
					},
					{
						title: "正规资质",
						intro: "工信部核准SP资质,移动、电信、联通等合作伙伴；对所有客户信息严格保密，确保客户信息安全性。"
					},
					{
						title: "售后无忧",
						intro: "专业群发短信技术团队提供技术支撑，售后提供服务支持，享受7X24小时售后服务，让客户尽享极致体验。"
					}
				],
				industry:[],
			}	
		},
		created() {
			this.getIndustry();
		},
		methods:{
			getIndustry(){
				this.$http.getIndustryList({type:2}).then(res => {
					if(res.data!=null){
						this.industry = res.data;
					}
				});
			},
			goAndroid(){
				window.open("https://www.pgyer.com/RUbM", '_blank');
			},
			goIos(){
				window.open("https://apps.apple.com/cn/app/%E7%9F%AD%E4%BF%A1%E7%BE%A4%E5%8F%91-106%E7%9F%AD%E4%BF%A1%E8%90%A5%E9%94%80%E6%89%BE%E5%AE%A2%E6%BA%90%E6%89%BE%E5%AE%A2%E6%88%B7%E6%89%BE%E5%95%86%E6%9C%BA%E9%98%B2%E5%B0%81%E5%8F%B7/id1562261462", '_blank');
			}
		}	
	}
</script>

<style scoped lang="less">
.banner{
	 padding-top: 80px;
	 height: 450px;
	 background-image: url(https://file.lanmeihulian.com/banner_3.png);
	 background-repeat: no-repeat;
	 background-size:100% 100%;
	 .banner-content{
		 width: 1200px;
		 margin: auto;
		 display: flex;
		 display: -webkit-flex;
		 flex-direction:row;
		 justify-content:center;
		 .banner-title{
			 width: 800px;
			 text-align: center;
			 .banner-sub-title{
				 display: inline-block;
				 margin-top: 60px;
				 font-size: 40px;
				 font-weight: 600;
				 color: white;
			 }
			 
			 .banner-sub-title1{
				display: inline-block;
				margin-top: 10px;
				font-size: 24px;
				color: white; 
			 }
			 .banner-qrcode{
				 margin: 20px auto;
				 text-align: center;
				 height: 200px;
				 width: 600px;
				 display: flex;
				 display: -webkit-flex;
				 flex-direction:row;
				 justify-content: flex-end;
				 .qr-div1{
					 margin: auto;
					 .qr-sub-text{
						 margin-top: 10px;
						 display: block;
						 margin-left: 20px;
						 width: 100px;
						 height: 30px;
						 border: 2px solid white;
						 border-radius: 10px;
					 	 font-size: 18px;
						 line-height: 30px;
					 	 color: #ffffff; 
						 cursor: pointer;
					 } 
					 .qr-sub-text:hover{
						  color: #ffffff; 
						   background-color: skyblue;
					 }
				 }
				 .qr-div{
					 margin-left: 40px;
					.qr-sub-text{
						 margin-top: 10px;
						 margin-left: 20px;
						 display: block;
						 width: 100px;
						 height: 30px;
						 border: 2px solid white;
						 border-radius: 10px;
						 font-size: 18px;
						 line-height: 30px;
						 color: #ffffff; 
						 cursor: pointer;
					} 
					.qr-sub-text:hover{
					   color: #ffffff; 
					   background-color: skyblue;
					}
				 }
				.qr-text-div{
					width: 200px;
					height: 150px;
					 /*flex 布局*/
					display: flex;
					/*实现垂直居中*/
					align-items: center;
					padding-left: 20px;
					.qr-text-link{
						cursor: pointer;
						font-size: 24px;
						color: #ffffff; 
					}
					.qr-text-link:hover{
						color:#B90F10;
					}
				}
			 }
		 }
		 .r_img{
			 img{
				 width: 219px;
				 height: 430px;
			 }
		 }
	 }
}
.main{
	width: 1200px;
	margin: auto;
	.msg{
		margin-top: 40px;
		height: 800px;
		.msg-title{
		 	font-size: 38px;
		 	font-weight: 600;
			line-height: 80px;
		 	text-align: center;
			.msg-border{
				margin: auto;
				height: 10px;
				width: 100px;
				background-color: #409EFF;
				border-radius: 5px;
			}
		}
		.msg-sub-title{
			margin-top: 20px;
			font-size: 20px;
			color: #666666;
			text-align: center;
		}
		.msg-content{
			margin-top: 60px;
			height: 500px;
			display: flex;
			display: -webkit-flex;
			flex-direction: row;
			justify-content: center;
			.msg-content-l{
				width: 400px;
				.msg-box{
					height: 200px;
					.msg-box-text{
						width: 300px;
						.box-text-title{
							font-size: 18px;
							color: #333333;
							line-height: 35px;
							font-weight: 600;
						}
						.box-text{
							font-size: 16px;
							color: #666666;
						}
					}
					.msg-border{
						height: 30px;
						width: 400px;
						display: flex;
						display: -webkit-flex;
						flex-direction: row-reverse;
						.msg-border-sub{
							height: 30px;
							width: 350px;
							border-bottom: 2px solid #666666;
							border-left: 2px solid #666666;
						}
					}
				}
			}
			.msg-content-m{
				text-align: center; 
				margin-left: 20px;
				margin-right: 20px;
			}
			.msg-content-r{
				width: 400px;
				.msg-box{
				 	height: 200px;
				 	.msg-box-text{
				 		width: 400px;
						padding-left: 40px;
						.box-text-title{
							font-size: 18px;
							line-height: 35px;
							font-weight: 600;
							color: #333333;
						}
				 		.box-text{
				 			font-size: 16px;
							color: #666666;
				 			
				 		}
				 	}
					.msg-border{
						height: 30px;
						width: 400px;
						display: flex;
						display: -webkit-flex;
						flex-direction: row;
						.msg-border-sub{
							height: 30px;
							width: 80px;
							border-bottom: 2px solid #666666;
							border-right: 2px solid #666666;
						}
					}
				}
			}
		}
	}
	.industry{
		margin-top: 100px;
		max-height: 950px;
	 	.industry-title{
	 		text-align: center;
	 		font-size: 40px;
	 		font-weight: 600;
			color: #131313;
	 		line-height: 50px;
			.industry-subtitle{
				font-size: 22px;
				color: #666666;
				font-weight: 500;
			}
	 	}
	 	.industry-content{
			height: auto;
	 		margin-top: 30px;
	 		display: -webkit-flex;
	 		display: flex;
	 		flex-direction: row;
	 		flex-wrap: wrap;
	 		justify-content: center;
	 		.industry-box{
	 			height: 330px;
	 			width: 280px;
	 			margin: 10px 10px 10px 10px;
	 			// padding-top: 50px;
	 			text-align: center;
	 			background-color: #F2F2F2;
	 			.box-icon{
					height: 120px;
					width: 280px;
					margin: auto;
					display:table-cell;
					vertical-align: bottom;
					text-align: center;
	 				img{
	 					width: 50px;
	 					height: 50px;
	 				}
	 			}
	 			.box-intro{
					margin-top: 20px;
	 				.title{
	 					font-size: 24px;
	 					font-weight:600;
	 					line-height: 65px;
	 				}
	 				.intro{
	 					font-size: 16px;
	 					line-height:30px;
	 					display: inline-block;
	 					width: 240px;
						padding-bottom: 40px;
						text-align: left;
	 				}
	 			}
	 		}	
	 		.industry-box:hover{
	 			.box-intro{
	 				.title{
	 					color: #409EFF;
	 				}
	 			}
	 		}
	 	}	
	}
    .process{
    	height: 350px;
    	margin-top: 50px;
    	.process-title{
    		text-align: center;
    		font-size: 40px;
    		font-weight: 600;
    		line-height: 80px;
			.msg-border{
				margin: auto;
				height: 10px;
				width: 100px;
				background-color: #409EFF;
				border-radius: 5px;
			}
    	}
    	.process-content{
    		.process-ul{
    			margin-top: 60px;
    			display: flex;
    			display: -webkit-flex;
    			flex-direction: row;
    			justify-content: center;
    			.process-ul-li{
    				width: 300px;
					height: 60px;
					padding-top: 20px;
					text-align: center;
					background-color: #F2F2F2;
    				.sub-title{
						padding-left: 20px;
						font-size: 20px;
					}	
    			}
				.process-ul-li-icon{
					width: 40px;
					height: 40px;
					padding-top: 20px;
					text-align: center;
				}
    		}
    	}
    }
 }
</style>
